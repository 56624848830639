import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"
import { buildPageDescription } from "../lib/helper"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import FormattedTitle from "../components/common/FormattedTitle"

const EventPage = props => {
  const { pageContext } = props
  const { title, body, thumbnail = {}, eventUrl } = pageContext
  const eventDate = new Date(pageContext?.eventDate * 1000).toLocaleString(
    "en-US",
    { year: "numeric", month: "long", day: "numeric" }
  )
  const image = getImage(thumbnail)
  return (
    <Layout>
      <h1>
        <FormattedTitle forceUnderline={false}>{title}</FormattedTitle>
      </h1>
      <div
        className="content-wrapper container position-relative"
        style={{ zIndex: "2" }}
      >
        {image && (
          <div className="py-4">
            <GatsbyImage
              alt={title}
              image={image}
              imgClassName="preview"
              loading="eager"
              className="rounded-4"
            />
          </div>
        )}
        <span>{eventDate}</span>
        <div
          dangerouslySetInnerHTML={{ __html: body?.processed }}
          className="node-body pt-4 clearfix"
        />
        {eventUrl && (
          <div className="py-2">
            <Link to={eventUrl} className="gold">
              {eventUrl}
            </Link>
          </div>
        )}
      </div>
      <p className="py-5" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/events" className="read-more white">
          See all events >>
        </Link>
      </p>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export default EventPage

export const Head = ({ pageContext }) => {
  const { title, body, entityUrl, imageUrl } = pageContext
  const description = buildPageDescription(body?.value)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname={entityUrl}
      image={imageUrl}
    />
  )
}
