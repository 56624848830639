import React from "react"

export const GA = ({ children }) => {
  const gaTrackingId = process.env.GATSBY_GA_TRACKING_ID;
  const legacyTrackingId = process.env.GATSBY_LEGACY_GA_TRACKING_ID;
  console.debug('ga traking id => ', gaTrackingId);
  console.debug('legacy ga traking id => ', legacyTrackingId);
  return (
    <>
      {children}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${legacyTrackingId}')
            gtag('config', '${gaTrackingId}');
          `,
        }}
      />
    </>
  )
}
