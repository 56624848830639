import React from "react"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import { removeAsterisks } from "../../lib/helper"
import { GA as GoogleAnalytics } from "./analytics"

export const SEO = ({ title, description, pathname, children, image }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
  } = useSiteMetadata()

  const seo = {
    title: removeAsterisks(title || defaultTitle),
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname || ``}`,
  }

  console.debug({ siteUrl, defaultImage, seo })
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="canonical" content={seo.url} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Slaight Music" />
      <meta name="og:image" content={seo.image} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content="@SlaightMusic" />
      <meta name="twitter:creator" content="@SlaightMusic" />
      {children}
      <GoogleAnalytics />
    </>
  )
}
